import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  Renderer2,
  signal,
  untracked,
  viewChild,
} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {
  AnimationService,
  CurrentDateLabelComponent,
  LayoutComponent,
  LoadingPageComponent,
  NavigationMenuComponent,
  SalaryLayoutContentDirective,
  SalaryLayoutLeftDirective,
  SalaryLayoutRightDirective,
} from '@salary/common/component';
import { DialogService } from '@salary/common/dialog';
import {
  AppSettingsFacade,
  LohnkontextFacade,
  SettingsFacade,
} from '@salary/common/facade';
import { isBrowserSupported } from '@salary/common/utils';
import {
  CellApiModule,
  CellStyleModule,
  ClientSideRowModelApiModule,
  ClientSideRowModelModule,
  ColumnApiModule,
  ColumnAutoSizeModule,
  CsvExportModule,
  CustomEditorModule,
  EventApiModule,
  ExternalFilterModule,
  GridStateModule,
  LocaleModule,
  ModuleRegistry,
  PinnedRowModule,
  provideGlobalGridOptions,
  QuickFilterModule,
  RenderApiModule,
  RowApiModule,
  RowAutoHeightModule,
  RowSelectionModule,
  RowStyleModule,
  ScrollApiModule,
  TooltipModule,
  ValidationModule,
} from 'ag-grid-community';
import {
  ClipboardModule,
  ContextMenuModule,
  LicenseManager,
  RowGroupingModule,
  ServerSideRowModelApiModule,
  ServerSideRowModelModule,
} from 'ag-grid-enterprise';
import { Settings } from 'luxon';
import { routeTransitionAnimations } from './app-component.animations';
import {
  AppUpdateService,
  AzureSalaryMonitoringService,
  ChunkLoadErrorHandlerService,
} from './services';
@Component({
  selector: 'salary-root',
  templateUrl: './app.component.html',
  styles: [
    `
      .fade-in {
        animation: fadeIn 2s cubic-bezier(0.25, 0.8, 0.25, 1) forwards;
      }
      @keyframes fadeIn {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [routeTransitionAnimations],
  imports: [
    LayoutComponent,
    SalaryLayoutLeftDirective,
    SalaryLayoutRightDirective,
    NavigationMenuComponent,
    CurrentDateLabelComponent,
    SalaryLayoutContentDirective,
    RouterOutlet,
    LoadingPageComponent,
  ],
})
export class AppComponent {
  private routingAnimationState:
    | 'list1'
    | 'list2'
    | 'detail1'
    | 'detail2'
    | 'void';
  private readonly agGridLicenseKey =
    'Using_this_{AG_Grid}_Enterprise_key_{AG-061319}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{BRZ_Deutschland_GmbH}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{BRZ_365}_only_for_{6}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{BRZ_365}_need_to_be_licensed___{BRZ_365}_has_been_granted_a_Deployment_License_Add-on_for_{6}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{20_April_2025}____[v3]_[01]_MTc0NTEwMzYwMDAwMA==21b57ee0c9fff1b906610f43d293efa1';

  private renderer2 = inject(Renderer2);
  private dialogService = inject(DialogService);
  private appSettingsFacade = inject(AppSettingsFacade);
  private animationService = inject(AnimationService);
  private lohnkontextFacade = inject(LohnkontextFacade);

  protected initialized = computed(
    () =>
      this.appSettingsFacade.select.loaded() &&
      this.lohnkontextFacade.select.loaded(),
  );
  protected loadingAnimationDone = signal(false);
  protected showApplication = computed(
    () => this.initialized() && this.loadingAnimationDone(),
  );
  protected showFadeInAnimation = window['Cypress'] == null;
  protected disableExpandCollapse = signal(false);
  protected isLeftSideNavExpanded = signal(false);
  private outlet = viewChild(RouterOutlet);
  private outletActivated = signal<boolean>(false, { equal: () => false });
  protected outletTrigger = computed(() => {
    const outlet = this.outlet();
    if (!outlet || !this.outletActivated()) {
      return undefined;
    }
    return untracked(() => this.getAnimationValue(outlet));
  });

  constructor(
    //the underscore injections should prevent treeshaking so that the services would never be instanciated
    _azureMonitoringService: AzureSalaryMonitoringService,
    _chunkLoadErrorHandlerService: ChunkLoadErrorHandlerService,
    settingsFacade: SettingsFacade,
    appUpdateService: AppUpdateService,
  ) {
    if (!isBrowserSupported()) {
      this.showBrowserNotSupportedMessage();
    }

    if (window['Cypress']) {
      const dateNow = window['dateNow'];
      if (dateNow != null) {
        Settings.now = () => dateNow;
      }
    }
    Settings.defaultZone = 'Europe/Berlin';
    Settings.defaultLocale = 'de-DE';
    Settings.defaultOutputCalendar = 'gregory';
    settingsFacade.initializeSettings();
    LicenseManager.setLicenseKey(this.agGridLicenseKey);

    ModuleRegistry.registerModules([
      ClientSideRowModelModule,
      ClientSideRowModelApiModule,
      ServerSideRowModelApiModule,
      ServerSideRowModelModule,
      ColumnAutoSizeModule,
      RowStyleModule,
      CellStyleModule,
      TooltipModule,
      LocaleModule,
      GridStateModule,
      ColumnApiModule,
      ScrollApiModule,
      RowApiModule,
      CellApiModule,
      EventApiModule,
      ContextMenuModule,
      ValidationModule,
      RenderApiModule,
      CustomEditorModule,
      PinnedRowModule,
      ExternalFilterModule,
      RowGroupingModule,
      RowSelectionModule,
      CsvExportModule,
      ClipboardModule,
      QuickFilterModule,
      RowAutoHeightModule,
    ]);
    provideGlobalGridOptions({ theme: 'legacy' });

    this.initializeDarkMode();
    appUpdateService.checkForUpdates();
  }

  private initializeDarkMode() {
    const bodyElement = document.getElementsByTagName('body')[0];
    const setting = this.appSettingsFacade.select.setting('darkMode');
    effect(() => {
      const darkMode = setting();
      untracked(() => {
        if (darkMode) {
          this.renderer2.addClass(bodyElement, 'darkMode');
          this.renderer2.removeClass(bodyElement, 'lightMode');
        } else {
          this.renderer2.addClass(bodyElement, 'lightMode');
          this.renderer2.removeClass(bodyElement, 'darkMode');
        }
      });
    });
  }

  private getAnimationValue(outlet: RouterOutlet) {
    if (outlet.activatedRouteData.animate === 'list') {
      this.routingAnimationState =
        this.routingAnimationState === 'list1' ? 'list2' : 'list1';
    } else if (outlet.activatedRouteData.animate === 'detail') {
      this.routingAnimationState =
        this.routingAnimationState === 'detail1' ? 'detail2' : 'detail1';
    } else {
      this.routingAnimationState = 'void';
    }
    return this.routingAnimationState;
  }

  protected onActivate() {
    this.outletActivated.set(true);
  }

  protected startOutletAnimation() {
    this.animationService.outletAnimationRunning.set(true);
  }

  protected endOutletAnimation() {
    this.animationService.outletAnimationRunning.set(false);
  }

  private showBrowserNotSupportedMessage() {
    this.dialogService.openDialog({
      title: 'Achtung',
      message: `Es wurde ein nicht unterstützter Browser erkannt.<br>
      Bei Verwendung eines nicht unterstützten Browsers kann es zu Darstellungs- sowie Funktionsfehlern kommen,
      die die Nutzung der Anwendung erschweren oder teilweise unmöglich machen.<br><br>
      Es wird dringend empfohlen einen der folgenden Browser in aktueller Version zu verwenden:<br>
      - Google Chrome<br>
      - Microsoft Edge<br><br>
      Supportanfragen bei Verwendung eines nicht unterstützten Browsers werden nicht übernommen.`,
      button1Caption: 'Verstanden',
      maxWidth: 750,
    });
  }
}
