import { inject, makeEnvironmentProviders } from '@angular/core';
import {
  CanMatchFn,
  provideRouter,
  Route,
  Router,
  Routes,
  UrlSegment,
  withComponentInputBinding,
} from '@angular/router';
import {
  AuthenticationService,
  MsalGuardWithMatchLogin,
} from '@salary/common/authentication';
import { AuthorizationService } from '@salary/common/authorization';
import { filter } from 'rxjs';
import { ROUTES as betriebsdatenRoutes } from '../administration/component/administration-betriebsdaten.routes';
import { ROUTES as administrationRechenzentrumRoutes } from '../administration/component/administration-rechenzentrum.routes';
import { ROUTES as administrationRoutes } from '../administration/component/administration.routes';
import { buildParameterSettings } from '../build-parameter-settings/build-parameter-settings';
import { ROUTES as calculationRoutes } from '../calculation/component/calculation.routes';
import { ROUTES as extraRoutes } from '../common/extras/common-extras.routes';
import { ROUTES as consolidationRechenzentrumRoutes } from '../consolidation/component/consolidation-rechenzentrum.routes';
import { ROUTES as consolidationRoutes } from '../consolidation/component/consolidation.routes';
import { ROUTES as zusatzstaemmeRoutes } from '../masterdata/component/masterdata-zusatzstaemme.routes';
import { ROUTES as stammdatenRoutes } from '../masterdata/component/masterdata.routes';
import { ROUTES as reportingRoutes } from '../reporting/component/reporting.routes';
import { ROUTES as transactionRoutes } from '../transaction/component/transaction.routes';

const waitForAuthenticationReady: CanMatchFn = () =>
  inject(AuthenticationService).isAuthenticated$.pipe(
    filter((isAuthenticated) => isAuthenticated),
  );
const rootGuards: Route['canMatch'] = [
  (route: Route, segement: UrlSegment[]) =>
    inject(MsalGuardWithMatchLogin).canMatchWithLogin(route, segement),
  waitForAuthenticationReady,
];
const rootGuardsWithAbrechnungskreiseFallback: Route['canMatch'] = [
  ...rootGuards,
  (route: Route) =>
    inject(AuthorizationService).getFirstChildWithReadPermission(
      route,
      route.path,
      true,
    )
      ? true
      : inject(Router).parseUrl('/betriebsdaten/abrechnungskreise'),
];

const ROUTES: Routes = [
  { path: '', redirectTo: 'administration', pathMatch: 'full' },
  {
    path: 'administration',
    children: administrationRoutes,
    canMatch: rootGuardsWithAbrechnungskreiseFallback,
    data: {
      navigation: {
        text: 'Administration',
        icon: 'security',
        availableWithoutAbrechnungskreisLohnkontext: true,
      },
    },
  },
  {
    path: 'rechenzentrum',
    children: [
      ...administrationRechenzentrumRoutes,
      ...consolidationRechenzentrumRoutes,
    ],
    canMatch: rootGuardsWithAbrechnungskreiseFallback,
    data: {
      navigation: {
        text: 'Rechenzentrum',
        icon: 'dns',
        availableWithoutAbrechnungskreisLohnkontext: true,
      },
    },
  },
  {
    path: 'betriebsdaten',
    children: betriebsdatenRoutes,
    canMatch: rootGuards,
    data: {
      navigation: {
        text: 'Betriebsdaten',
        icon: 'assured_workload',
        availableWithoutAbrechnungskreisLohnkontext: true,
      },
    },
  },
  {
    path: 'stammdaten',
    children: stammdatenRoutes,
    canMatch: rootGuards,
    data: { navigation: { text: 'Stammdaten', icon: 'store' } },
  },
  {
    path: 'zusatzstaemme',
    children: zusatzstaemmeRoutes,
    canMatch: rootGuards,
    data: { navigation: { text: 'Zusatzstämme', icon: 'add_business' } },
  },
  {
    path: 'bewegungsdaten',
    children: transactionRoutes,
    canMatch: rootGuards,
    data: { navigation: { text: 'Bewegungsdaten', icon: 'autorenew' } },
  },
  {
    path: 'abrechnung',
    children: calculationRoutes,
    canMatch: rootGuards,
    data: { navigation: { text: 'Abrechnung', icon: 'spellcheck' } },
  },
  {
    path: 'ermittlunguebermittlung',
    children: consolidationRoutes,
    canMatch: rootGuards,
    data: {
      navigation: { text: 'Ermittlung und Übermittlung', icon: 'watch_later' },
    },
  },
  {
    path: 'auswertungen',
    children: reportingRoutes,
    canMatch: rootGuards,
    data: { navigation: { text: 'Auswertungen', icon: 'assessment' } },
  },
  { path: 'extras', children: extraRoutes, outlet: 'sidebar' },
  ...buildParameterSettings.e2eRoutes,
];

export function provideAppRouting() {
  return makeEnvironmentProviders([
    provideRouter(ROUTES, withComponentInputBinding()),
  ]);
}
