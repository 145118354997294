import { computed, inject } from '@angular/core';
import { Routes } from '@angular/router';
import {
  ImportRegistrationService,
  createRoute,
  extendDomainRoutes,
  redirectFirstSibling,
} from '@salary/common/component';
import { Rolle } from '@salary/common/dumb';

export const ROUTES: Routes = extendDomainRoutes([
  { path: '', redirectTo: redirectFirstSibling, pathMatch: 'full' },
  createRoute({
    path: 'importe',
    list: () =>
      import('./importe/importe-overview.component').then(
        (c) => c.ImporteOverviewComponent,
      ),
    navigation: {
      text: 'Importe',
      icon: 'north',
      index: 0,
      synonyms: () => {
        const importService = inject(ImportRegistrationService);
        return computed(() =>
          importService
            .importConfigs()
            .map((c) => c.title)
            .concat('blz'),
        );
      },
    },
    authorization: {
      permissions: {
        allowNone: [
          Rolle.HL,
          Rolle.VS,
          Rolle.VSNL,
          Rolle.VSGL,
          Rolle.TS,
          Rolle.AV,
        ],
        allowAll: [Rolle.PM, Rolle.DEV],
      },
    },
  }),
]);
