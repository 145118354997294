export const APP_IMPORT_CONFIG_REGISTRATION = [
  () =>
    import('../administration/component/administration-import.config').then(
      (m) => m.IMPORT_CONFIG_REGISTRATION,
    ),
  () =>
    import('../masterdata/component/masterdata-import.config').then(
      (m) => m.IMPORT_CONFIG_REGISTRATION,
    ),
  () =>
    import('../transaction/component/transaction-import.config').then(
      (m) => m.IMPORT_CONFIG_REGISTRATION,
    ),
  () =>
    import('../calculation/component/calculation-import.config').then(
      (m) => m.IMPORT_CONFIG_REGISTRATION,
    ),
  () =>
    import('../consolidation/component/consolidation-import.config').then(
      (m) => m.IMPORT_CONFIG_REGISTRATION,
    ),
];
