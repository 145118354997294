import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { EnvironmentConfigService } from '@salary/common/api/base-http-service';
import { AppComponent } from './app/app.component';
import { provideApp } from './app/app.config';
import { buildParameterSettings } from './build-parameter-settings/build-parameter-settings';

if (buildParameterSettings.production) {
  enableProdMode();
}

const configFileName = 'config.json';
fetch('/assets/config/' + configFileName)
  .then((response) => {
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  })
  .then((json: EnvironmentConfigService) => {
    if (
      !json.apiAdministrationUrl ||
      !json.apiMasterdataUrl ||
      !json.apiCalculationUrl ||
      !json.apiTransactionsUrl ||
      !json.apiReportingUrl ||
      !json.apiConsolidationUrl ||
      !json.apiOutboundUrl ||
      !json.apiNotificationUrl ||
      !json.azureB2C
    ) {
      throw new Error(`Invalid ${configFileName}: ${JSON.stringify(json)}`);
    }
    return Object.assign(new EnvironmentConfigService(), json);
  })
  .then((configService) => {
    registerLocaleData(localeDe, 'de-DE');
    bootstrapApplication(AppComponent, {
      providers: [
        { provide: EnvironmentConfigService, useValue: configService },
        provideApp(),
      ],
    }).catch((err) => console.error(err));
  })
  .catch(
    (err) =>
      (document.body.innerHTML = `<h1>Fehler beim Laden der ${configFileName}</h1> <p style="color: red">${err.message}</p>`),
  );
