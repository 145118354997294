import { Routes } from '@angular/router';
import { DEV_READ_REST_ALL_PERMISSIONS } from '@salary/common/authorization';
import {
  createRoute,
  extendDomainRoutes,
  redirectFirstSibling,
} from '@salary/common/component';
import { MASTERDATA_DEFINITIONS } from '../masterdata-definitions';

export const ROUTES: Routes = extendDomainRoutes([
  { path: '', redirectTo: redirectFirstSibling, pathMatch: 'full' },
  createRoute({
    path: 'berufsgruppen',
    list: () =>
      import('./berufsgruppen/list/berufsgruppen-list.component').then(
        (c) => c.BerufsgruppenListComponent,
      ),
    dsm: 'all',
    navigation: {
      icon: MASTERDATA_DEFINITIONS.berufsgruppe.icon,
      text: MASTERDATA_DEFINITIONS.berufsgruppe.captionPlural,
    },
    authorization: { permissions: DEV_READ_REST_ALL_PERMISSIONS },
  }),
  createRoute({
    path: 'lohnverbuchungskennzeichen',
    list: () =>
      import(
        './lohnverbuchungskennzeichen/list/lohnverbuchungskennzeichen-list.component'
      ).then((c) => c.LohnverbuchungskennzeichenListComponent),
    dsm: 'all',
    navigation: {
      icon: MASTERDATA_DEFINITIONS.lohnverbuchungskennzeichen.icon,
      text: MASTERDATA_DEFINITIONS.lohnverbuchungskennzeichen.captionPlural,
    },
    authorization: { permissions: DEV_READ_REST_ALL_PERMISSIONS },
  }),
  createRoute({
    path: 'symbole',
    list: () =>
      import('./symbole/list/symbole-list.component').then(
        (c) => c.SymboleListComponent,
      ),
    dsm: 'all',
    navigation: {
      icon: MASTERDATA_DEFINITIONS.symbol.icon,
      text: MASTERDATA_DEFINITIONS.symbol.captionPlural,
    },
    authorization: { permissions: DEV_READ_REST_ALL_PERMISSIONS },
  }),
  createRoute({
    path: 'abteilungen',
    list: () =>
      import('./abteilungen/list/abteilungen-list.component').then(
        (c) => c.AbteilungenListComponent,
      ),
    dsm: 'withoutStandard',
    navigation: {
      icon: MASTERDATA_DEFINITIONS.abteilung.icon,
      text: MASTERDATA_DEFINITIONS.abteilung.captionPlural,
    },
    authorization: { permissions: DEV_READ_REST_ALL_PERMISSIONS },
  }),
  createRoute({
    path: 'tarifgruppen',
    list: () =>
      import('./tarifgruppen/list/tarifgruppen-list.component').then(
        (c) => c.TarifgruppenListComponent,
      ),
    detail: () =>
      import('./tarifgruppen/detail/tarifgruppen-detail.component').then(
        (c) => c.TarifgruppenDetailComponent,
      ),
    dsm: 'all',
    navigation: {
      icon: MASTERDATA_DEFINITIONS.tarifgruppe.icon,
      text: MASTERDATA_DEFINITIONS.tarifgruppe.captionPlural,
    },
    authorization: { permissions: DEV_READ_REST_ALL_PERMISSIONS },
  }),
  createRoute({
    path: 'steuerschluessel',
    list: () =>
      import('./steuerschluessel/list/steuerschluessel-list.component').then(
        (c) => c.SteuerschluesselListComponent,
      ),
    detail: () =>
      import(
        './steuerschluessel/detail/steuerschluessel-detail.component'
      ).then((c) => c.SteuerschluesselDetailComponent),
    dsm: 'all',
    navigation: {
      icon: MASTERDATA_DEFINITIONS.steuerschluessel.icon,
      text: MASTERDATA_DEFINITIONS.steuerschluessel.captionPlural,
    },
    authorization: { permissions: DEV_READ_REST_ALL_PERMISSIONS },
  }),
  createRoute({
    path: 'aganteilvbabgrenzungen',
    list: () =>
      import(
        './aganteilvbabgrenzungen/list/aganteilvbabgrenzungen-list.component'
      ).then((c) => c.AGAnteilVBAbrenzungenListComponent),
    detail: () =>
      import(
        './aganteilvbabgrenzungen/detail/aganteilvbabgrenzungen-detail.component'
      ).then((c) => c.AGAnteilVBAbrenzungenDetailComponent),
    dsm: 'all',
    navigation: {
      icon: MASTERDATA_DEFINITIONS.agAnteilVBAbgrenzung.icon,
      text: MASTERDATA_DEFINITIONS.agAnteilVBAbgrenzung.captionPlural,
      synonyms: ['vermögen'],
    },
    authorization: { permissions: DEV_READ_REST_ALL_PERMISSIONS },
  }),
]);
