import { ErrorHandler, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  AngularPlugin,
  ApplicationinsightsAngularpluginErrorService,
} from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { EnvironmentConfigService } from '@salary/common/api/base-http-service';

@Injectable()
export class AzureSalaryMonitoringService {
  private appInsights: ApplicationInsights;
  constructor(environmentConfig: EnvironmentConfigService, router: Router) {
    ApplicationinsightsAngularpluginErrorService.instance.addErrorHandler(
      new ErrorHandler(),
    );
    if (!window['Cypress'] && environmentConfig.appInsightsInstrumentationKey) {
      const angularPlugin = new AngularPlugin();
      this.appInsights = new ApplicationInsights({
        config: {
          enableAutoRouteTracking: true,
          instrumentationKey: environmentConfig.appInsightsInstrumentationKey,
          extensions: [angularPlugin],
          extensionConfig: {
            [angularPlugin.identifier]: { router },
            ['AppInsightsCfgSyncPlugin']: {
              cfgUrl: '', // this will block fetching from default cdn and we don't have to adjust CSP
            },
          },
        },
      });
      ApplicationinsightsAngularpluginErrorService.instance.plugin =
        this.appInsights;
      this.appInsights.loadAppInsights();
    }
  }
}
