import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  ErrorHandler,
  inject,
  isDevMode,
  LOCALE_ID,
  makeEnvironmentProviders,
  provideEnvironmentInitializer,
  provideExperimentalZonelessChangeDetection,
} from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import {
  EnvironmentConfigService,
  JsonDateInterceptor,
  PendingRequestsInterceptor,
  TrimInterceptor,
} from '@salary/common/api/base-http-service';
import { provideAuthentication } from '@salary/common/authentication';
import {
  provideCommonComponents,
  withImportConfigs,
  withListConfigs,
} from '@salary/common/component';
import { provideDumbComponents } from '@salary/common/dumb-components';
import { buildParameterSettings } from '../build-parameter-settings/build-parameter-settings';
import { APP_IMPORT_CONFIG_REGISTRATION } from './app-import.configs';
import { APP_LIST_CONFIG_REGISTRATION } from './app-list.configs';
import { provideAppRouting } from './app.routes';
import { CustomRouteReuseStrategy } from './custom-route-reuse-strategy';
import {
  AzureSalaryMonitoringService,
  ChunkLoadErrorHandlerService,
} from './services';

export function provideApp() {
  return makeEnvironmentProviders([
    provideExperimentalZonelessChangeDetection(),
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService,
    },
    AzureSalaryMonitoringService,
    ChunkLoadErrorHandlerService,
    { provide: HTTP_INTERCEPTORS, useClass: TrimInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JsonDateInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PendingRequestsInterceptor,
      multi: true,
    },
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    provideDumbComponents(),
    provideCommonComponents(
      withListConfigs(APP_LIST_CONFIG_REGISTRATION),
      withImportConfigs(APP_IMPORT_CONFIG_REGISTRATION),
    ),
    provideAppRouting(),
    provideAuthentication(),
    provideServiceWorker('ngsw-worker.js', {
      enabled: buildParameterSettings.serviceWoker,
    }),
    provideEnvironmentInitializer(() => {
      if (isDevMode()) {
        console.log(inject(EnvironmentConfigService));
      }
    }),
  ]);
}
