import { Routes } from '@angular/router';
import {
  createRoute,
  extendDomainRoutes,
  redirectFirstSibling,
} from '@salary/common/component';
import { Rolle } from '@salary/common/dumb';
import { CONSOLIDATION_DEFINITIONS } from '../consolidation-definitions';
import { RZ_PROZESSE } from './rz-prozesse/rz-prozesse-config';
import { RZ_PROZESSE_ROUTES } from './rz-prozesse/rz-prozesse-routing';

const rzProcessRoute = createRoute({
  path: 'rzprozesse',
  list: () =>
    import('./rz-prozesse/rz-prozesse-overview.component').then(
      (c) => c.RZProzesseOverviewComponent,
    ),
  navigation: {
    text: 'RZ-Prozesse',
    icon: 'mitre',
    index: 2,
    synonyms: RZ_PROZESSE.map((p) => p.caption).concat('lsta', 'lstb'),
  },
  authorization: {
    permissions: {
      allowNone: [Rolle.HL, Rolle.VS, Rolle.VSNL, Rolle.VSGL, Rolle.TS],
      allowAll: [Rolle.DEV, Rolle.PM, Rolle.AV],
    },
  },
});

export const ROUTES: Routes = extendDomainRoutes([
  { path: '', redirectTo: redirectFirstSibling, pathMatch: 'full' },
  createRoute({
    path: 'svdateifolgenummern',
    list: () =>
      import('./sv-dateifolgenummern/sv-dateifolgenummern-list.component').then(
        (c) => c.SVDateifolgenummernListComponent,
      ),
    navigation: {
      icon: CONSOLIDATION_DEFINITIONS.svDateifolgenummer.icon,
      text: CONSOLIDATION_DEFINITIONS.svDateifolgenummer.captionPlural,
      index: 1,
    },
    authorization: {
      permissions: {
        allowNone: [Rolle.HL, Rolle.VS, Rolle.VSNL, Rolle.VSGL, Rolle.TS],
        allowAll: [Rolle.DEV, Rolle.PM, Rolle.AV],
      },
    },
  }),
  {
    ...rzProcessRoute,
    children: rzProcessRoute.children.concat(...RZ_PROZESSE_ROUTES),
  },
]);
