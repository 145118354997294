import { Routes } from '@angular/router';
import {
  AuthorizationConfig,
  DEV_READ_REST_ALL_PERMISSIONS,
} from '@salary/common/authorization';
import {
  createRoute,
  extendDomainRoutes,
  redirectFirstSibling,
} from '@salary/common/component';
import { Rolle } from '@salary/common/dumb';
import { ADMINISTRATION_DEFINITIONS } from '../administration-definitions';

const BETRIEBSDATEN_DEFAULT: AuthorizationConfig['permissions'] = {
  allowRead: [Rolle.DEV, Rolle.VS, Rolle.VSNL, Rolle.TS],
  allowAll: [Rolle.PM, Rolle.AV, Rolle.HL, Rolle.VSGL],
};

export const ROUTES: Routes = extendDomainRoutes([
  { path: '', redirectTo: redirectFirstSibling, pathMatch: 'full' },
  createRoute({
    path: 'verbuchungsbetriebe',
    list: () =>
      import('./mandanten/list/mandanten-list.component').then(
        (c) => c.MandantenListComponent,
      ),
    detail: () =>
      import('./mandanten/detail/mandanten-detail.component').then(
        (c) => c.MandantenDetailComponent,
      ),
    navigation: {
      icon: ADMINISTRATION_DEFINITIONS.mandant.icon,
      text: ADMINISTRATION_DEFINITIONS.mandant.captionPlural,
      synonyms: ['mandanten'],
    },
    authorization: { permissions: BETRIEBSDATEN_DEFAULT },
  }),
  createRoute({
    path: 'beschaeftigungsbetriebe',
    list: () =>
      import(
        './beschaeftigungsbetriebe/list/beschaeftigungsbetriebe-list.component'
      ).then((m) => m.BeschaeftigungsbetriebeListComponent),
    detail: () =>
      import(
        './beschaeftigungsbetriebe/detail/beschaeftigungsbetriebe-detail.component'
      ).then((m) => m.BeschaeftigungsbetriebeDetailComponent),
    withValidationSupport: true,
    navigation: {
      icon: ADMINISTRATION_DEFINITIONS.beschaeftigungsbetrieb.icon,
      text: ADMINISTRATION_DEFINITIONS.beschaeftigungsbetrieb.captionPlural,
    },
    authorization: {
      permissions: BETRIEBSDATEN_DEFAULT,
      children: [
        { path: 'Ynb75N', permissions: DEV_READ_REST_ALL_PERMISSIONS },
      ],
    },
  }),
  createRoute({
    path: 'lohnsteuernummern',
    list: () =>
      import('./lohnsteuernummern/list/lohnsteuernummer-list.component').then(
        (m) => m.LohnsteuernummernListComponent,
      ),
    detail: () =>
      import(
        './lohnsteuernummern/detail/lohnsteuernummern-detail.component'
      ).then((m) => m.LohnsteuernummernDetailComponent),
    navigation: {
      icon: ADMINISTRATION_DEFINITIONS.finanzamt.icon,
      text: ADMINISTRATION_DEFINITIONS.finanzamt.captionPlural,
      synonyms: ['lohnsteuernummern'],
    },
    authorization: { permissions: BETRIEBSDATEN_DEFAULT },
  }),
  createRoute({
    path: 'betriebsozialkassen',
    list: () =>
      import(
        './betrieb-sozialkassen/list/betrieb-sozialkassen-list.component'
      ).then((m) => m.BetriebSozialkassenListComponent),
    detail: () =>
      import(
        './betrieb-sozialkassen/detail/betrieb-sozialkassen-detail.component'
      ).then((m) => m.BetriebSozialkassenDetailComponent),
    navigation: {
      icon: ADMINISTRATION_DEFINITIONS.sozialkasse.icon,
      text: ADMINISTRATION_DEFINITIONS.sozialkasse.captionPlural,
      synonyms: ['soka', 'zvk'],
    },
    authorization: { permissions: BETRIEBSDATEN_DEFAULT },
  }),
  createRoute({
    path: 'unfallversicherungen',
    list: () =>
      import(
        './unfallversicherungen/list/unfallversicherungen-list.component'
      ).then((m) => m.UnfallversicherungenListComponent),
    detail: () =>
      import(
        './unfallversicherungen/detail/unfallversicherungen-detail.component'
      ).then((m) => m.UnfallversicherungenDetailComponent),
    navigation: {
      icon: ADMINISTRATION_DEFINITIONS.unfallversicherung.icon,
      text: ADMINISTRATION_DEFINITIONS.unfallversicherung.captionPlural,
      synonyms: ['unfallversicherungen'],
    },
  }),
  createRoute({
    path: 'abrechnungskreise',
    list: () =>
      import('./abrechnungskreise/list/abrechnungskreise-list.component').then(
        (m) => m.AbrechnungskreiseListComponent,
      ),
    detail: () =>
      import(
        './abrechnungskreise/detail/abrechnungskreise-detail.component'
      ).then((m) => m.AbrechnungskreiseDetailComponent),
    withValidationSupport: true,
    navigation: {
      icon: ADMINISTRATION_DEFINITIONS.abrechnungskreis.icon,
      text: ADMINISTRATION_DEFINITIONS.abrechnungskreis.captionPlural,
    },
    authorization: {
      permissions: {
        allowRead: [Rolle.DEV],
        allowChange: [Rolle.HL, Rolle.VS, Rolle.VSNL, Rolle.VSGL, Rolle.TS],
        allowAll: [Rolle.AV, Rolle.PM],
      },
      children: [
        { path: 'CxmucY', permissions: DEV_READ_REST_ALL_PERMISSIONS },
        {
          path: 'u1h9YN',
          permissions: {
            allowRead: [Rolle.DEV, Rolle.VS, Rolle.TS],
            allowAll: [Rolle.AV, Rolle.PM, Rolle.HL, Rolle.VSNL, Rolle.VSGL],
          },
        },
      ],
    },
  }),
  createRoute({
    path: 'konzerne',
    list: () =>
      import('./konzerne/list/konzerne-list.component').then(
        (m) => m.KonzerneListComponent,
      ),
    detail: () =>
      import('./konzerne/detail/konzerne-detail.component').then(
        (m) => m.KonzerneDetailComponent,
      ),
    navigation: {
      icon: ADMINISTRATION_DEFINITIONS.konzern.icon,
      text: ADMINISTRATION_DEFINITIONS.konzern.captionPlural,
    },
    authorization: { permissions: BETRIEBSDATEN_DEFAULT },
  }),
]);
