import { Routes } from '@angular/router';
import {
  ALL_PERMISSIONS,
  DEV_READ_REST_ALL_PERMISSIONS,
} from '@salary/common/authorization';
import {
  createRoute,
  extendDomainRoutes,
  redirectFirstSibling,
} from '@salary/common/component';
import { CONSOLIDATION_DEFINITIONS } from '../consolidation-definitions';
import { getMeldungenOverviewConfigs } from './meldungen/overview/overview-registration';
import { getRueckmeldungenOverviewConfigs } from './rueckmeldungen/overview/rueckmeldungen-overview-registration';

export const ROUTES: Routes = extendDomainRoutes([
  { path: '', redirectTo: redirectFirstSibling, pathMatch: 'full' },
  createRoute({
    path: 'deuev',
    list: () =>
      import('./deuev/list/deuev-list.component').then(
        (c) => c.DeuevListComponent,
      ),
    detail: () =>
      import('./deuev/detail/deuev-detail.component').then(
        (c) => c.DeuevDetailComponent,
      ),
    withoutHinzufuegen: true,
    navigation: {
      icon: CONSOLIDATION_DEFINITIONS.deuev.icon,
      text: CONSOLIDATION_DEFINITIONS.deuev.captionPlural,
      synonyms: ['dsbd', 'dsak', 'freigabe'],
    },
    authorization: { permissions: ALL_PERMISSIONS },
  }),
  createRoute({
    path: 'a1antraege',
    list: () =>
      import('./a1-antraege/list/a1-antraege-list.component').then(
        (c) => c.A1AntraegeListComponent,
      ),
    detail: () =>
      import('./a1-antraege/detail/a1-antraege-detail.component').then(
        (c) => c.A1AntraegeDetailComponent,
      ),
    dsm: 'withoutStandard',
    withValidationSupport: true,
    navigation: {
      icon: CONSOLIDATION_DEFINITIONS.a1Antrag.icon,
      text: CONSOLIDATION_DEFINITIONS.a1Antrag.captionPlural,
      synonyms: ['entsendung', 'ausnahmevereinbarung'],
    },
    authorization: { permissions: DEV_READ_REST_ALL_PERMISSIONS },
  }),
  createRoute({
    path: 'arbeitsbescheinigungen',
    list: () =>
      import(
        './arbeitsbescheinigungen/list/arbeitsbescheinigungen-list.component'
      ).then((c) => c.ArbeitsbescheinigungenListComponent),
    detail: () =>
      import(
        './arbeitsbescheinigungen/detail/arbeitsbescheinigungen-detail.component'
      ).then((c) => c.ArbeitsbescheinigungenDetailComponent),
    dsm: 'withoutStandard',
    withValidationSupport: true,
    navigation: {
      icon: CONSOLIDATION_DEFINITIONS.arbeitsbescheinigung.icon,
      text: CONSOLIDATION_DEFINITIONS.arbeitsbescheinigung.captionPlural,
      synonyms: ['babea', 'nebeneinkommen'],
    },
    authorization: { permissions: DEV_READ_REST_ALL_PERMISSIONS },
  }),
  createRoute({
    path: 'entgeltersatzleistungen',
    list: () =>
      import(
        './entgeltersatzleistungen/list/entgeltersatzleistungen-list.component'
      ).then((c) => c.EntgeltersatzleistungenListComponent),
    detail: () =>
      import(
        './entgeltersatzleistungen/detail/entgeltersatzleistungen-detail.component'
      ).then((c) => c.EntgeltersatzleistungenDetailComponent),
    dsm: 'withoutStandard',
    withValidationSupport: true,
    navigation: {
      icon: CONSOLIDATION_DEFINITIONS.entgeltersatzleistung.icon,
      text: CONSOLIDATION_DEFINITIONS.entgeltersatzleistung.captionPlural,
      synonyms: [
        'eel',
        'krankengeld',
        'kinderkrankengeld',
        'mutterschaftsgeld',
        'übergangsgeld',
        'verletztengeld',
        'vorerkrankungszeiten',
      ],
    },
    authorization: { permissions: DEV_READ_REST_ALL_PERMISSIONS },
  }),
  {
    path: 'meldungen',
    data: {
      overviewData: getMeldungenOverviewConfigs,
      navigation: {
        text: 'Meldungen',
        icon: 'comment',
        synonyms: [
          'eel',
          'entgeltersatzleistungen',
          'lohnsteueranmeldungen',
          'aufwendungsausgleichsgesetz',
          'sofortmeldungen',
          'versicherungsnummern',
          'deuev',
        ],
      },
      authorization: { permissions: DEV_READ_REST_ALL_PERMISSIONS },
    },
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./meldungen/overview/meldungen-overview.component').then(
            (m) => m.MeldungenOverviewComponent,
          ),
      },
      createRoute({
        path: 'entgeltersatzleistungen',
        list: () =>
          import(
            './meldungen/meldungen-entgeltersatzleistung-list.component'
          ).then((c) => c.MeldungenEntgeltersatzleistungenListComponent),
        authorization: { permissions: DEV_READ_REST_ALL_PERMISSIONS },
      }),
      createRoute({
        path: 'lohnsteueranmeldungen',
        list: () =>
          import(
            './meldungen/meldungen-lohnsteueranmeldungen-list.component'
          ).then((c) => c.MeldungenLohnsteueranmeldungenListComponent),
        detail: () =>
          import(
            './meldungen/lohnsteueranmeldungen-korrekturen/lohnsteueranmeldungen-detail.component'
          ).then((c) => c.LohnsteueranmeldungenDetailComponent),
        dsm: 'withoutStandard',
        authorization: { permissions: DEV_READ_REST_ALL_PERMISSIONS },
      }),
      createRoute({
        path: 'aufwendungsausgleichsgesetz',
        list: () =>
          import('./meldungen/meldungen-aag-list.component').then(
            (c) => c.MeldungenAAGListComponent,
          ),
        authorization: { permissions: DEV_READ_REST_ALL_PERMISSIONS },
      }),
      createRoute({
        path: 'sofortmeldungen',
        list: () =>
          import('./meldungen/meldungen-sofortmeldungen-list.component').then(
            (c) => c.MeldungenSofortmeldungenListComponent,
          ),
        authorization: { permissions: DEV_READ_REST_ALL_PERMISSIONS },
      }),
      createRoute({
        path: 'versicherungsnummern',
        list: () =>
          import(
            './meldungen/meldungen-versicherungsnummern-abfrage-list.component'
          ).then((c) => c.MeldungenVersicherungsnummernListComponent),
        authorization: { permissions: DEV_READ_REST_ALL_PERMISSIONS },
      }),
      createRoute({
        path: 'beitragsnachweise',
        list: () =>
          import('./meldungen/meldungen-beitragsnachweise-list.component').then(
            (c) => c.MeldungenBeitragsnachweiseListComponent,
          ),
        authorization: { permissions: DEV_READ_REST_ALL_PERMISSIONS },
      }),
      createRoute({
        path: 'arbeitsunfaehigkeitsmeldungen',
        list: () =>
          import('./meldungen/meldungen-eau-list.component').then(
            (c) => c.MeldungenEAUListComponent,
          ),
        authorization: { permissions: DEV_READ_REST_ALL_PERMISSIONS },
      }),
      createRoute({
        path: 'deuevmeldungen',
        list: () =>
          import('./meldungen/meldungen-deuev-list.component').then(
            (c) => c.MeldungenDeuevListComponent,
          ),
        authorization: { permissions: DEV_READ_REST_ALL_PERMISSIONS },
      }),
    ],
  },
  {
    path: 'rueckmeldungen',
    data: {
      overviewData: getRueckmeldungenOverviewConfigs,
      navigation: {
        text: 'Rückmeldungen',
        icon: 'rv_hookup',
        synonyms: [
          'eel',
          'entgeltersatzleistung',
          'Versicherungsnummer',
          'arbeitsunfaehigkeitsmeldungen',
        ],
      },
      authorization: { permissions: DEV_READ_REST_ALL_PERMISSIONS },
    },
    children: [
      {
        path: '',
        loadComponent: () =>
          import(
            './rueckmeldungen/overview/rueckmeldungen-overview.component'
          ).then((m) => m.RueckmeldungenOverviewComponent),
      },
      createRoute({
        path: 'entgeltersatzleistungen',
        list: () =>
          import(
            './rueckmeldungen/rueckmeldungen-entgeltersatzleistung-list.component'
          ).then((c) => c.RueckmeldungenEntgeltersatzleistungenListComponent),
        authorization: { permissions: DEV_READ_REST_ALL_PERMISSIONS },
      }),
      createRoute({
        path: 'versicherungsnummern',
        list: () =>
          import(
            './rueckmeldungen/rueckmeldungen-versicherungsnummern-abfrage-list.component'
          ).then(
            (c) => c.RueckmeldungenVersicherungsnummernAbfrageListComponent,
          ),
        authorization: { permissions: DEV_READ_REST_ALL_PERMISSIONS },
      }),
      createRoute({
        path: 'sofortmeldungen',
        list: () =>
          import(
            './rueckmeldungen/rueckmeldungen-sofortmeldungen-list.component'
          ).then((c) => c.RueckmeldungenSofortmeldungenListComponent),
        authorization: { permissions: DEV_READ_REST_ALL_PERMISSIONS },
      }),
      createRoute({
        path: 'aufwendungsausgleichsgesetz',
        list: () =>
          import('./rueckmeldungen/rueckmeldungen-aag-list.component').then(
            (c) => c.RueckmeldungenAAGListComponent,
          ),
        authorization: { permissions: DEV_READ_REST_ALL_PERMISSIONS },
      }),
      createRoute({
        path: 'arbeitsunfaehigkeitsmeldungen',
        list: () =>
          import('./rueckmeldungen/rueckmeldungen-eau-list.component').then(
            (c) => c.RueckmeldungenEauListComponent,
          ),
        authorization: { permissions: DEV_READ_REST_ALL_PERMISSIONS },
      }),
      createRoute({
        path: 'deuevrueckmeldungen',
        list: () =>
          import('./rueckmeldungen/rueckmeldungen-deuev-list.component').then(
            (c) => c.RueckmeldungenDeuevListComponent,
          ),
        authorization: { permissions: DEV_READ_REST_ALL_PERMISSIONS },
      }),
    ],
  },
  createRoute({
    path: 'vortragswertekrankenkasse',
    list: () =>
      import(
        './vortragswerte-krankenkassen/list/vortragswerte-krankenkasse-list.component'
      ).then((c) => c.VortragswerteKrankenkasseListComponent),
    detail: () =>
      import(
        './vortragswerte-krankenkassen/detail/vortragswerte-krankenkasse-detail.component'
      ).then((c) => c.VortragswerteKrankenkasseDetailComponent),
    customDsm: ['abrechnungskreis'],
    navigation: {
      icon: CONSOLIDATION_DEFINITIONS.vortragswerteKrankenkasse.icon,
      text: CONSOLIDATION_DEFINITIONS.vortragswerteKrankenkasse.captionPlural,
    },
    authorization: { permissions: DEV_READ_REST_ALL_PERMISSIONS },
  }),
  createRoute({
    path: 'zahlungsdateien',
    list: () =>
      import('./zahlungsdateien/list/zahlungsdateien-list.component').then(
        (c) => c.ZahlungsdateienListComponent,
      ),
    detail: () =>
      import(
        './zahlungsdateien/list/zahlungsdateien-detail-zahlungen-list.component'
      ).then((c) => c.ZahlungsdateienDetailZahlungenListComponent),
    navigation: {
      icon: CONSOLIDATION_DEFINITIONS.zahlungsdateien.icon,
      text: CONSOLIDATION_DEFINITIONS.zahlungsdateien.captionPlural,
    },
    authorization: { permissions: DEV_READ_REST_ALL_PERMISSIONS },
  }),
]);
