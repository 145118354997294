import { Routes } from '@angular/router';
import { DEV_READ_REST_ALL_PERMISSIONS } from '@salary/common/authorization';
import {
  createRoute,
  extendDomainRoutes,
  ListDsmPerspectivesContainerComponent,
  redirectFirstSibling,
} from '@salary/common/component';
import { Personal, Rolle } from '@salary/common/dumb';
import { LohnkontextReadonly } from '@salary/common/facade';
import { MASTERDATA_DEFINITIONS } from '../masterdata-definitions';

const lohnartenChildren: Routes = [
  {
    path: '',
    component: ListDsmPerspectivesContainerComponent,
    children: [
      { path: '', redirectTo: 'standard', pathMatch: 'full' },
      {
        path: 'standard',
        children: [
          {
            path: '',
            loadComponent: () =>
              import('./lohnarten/list/lohnarten-list.component').then(
                (m) => m.LohnartenListComponent,
              ),
          },
          {
            path: 'sekundaere',
            loadComponent: () =>
              import('./lohnarten/list/lohnarten-list.component').then(
                (m) => m.LohnartenListComponent,
              ),
          },
        ],
      },
      {
        path: 'lizenznehmer',
        loadComponent: () =>
          import('./lohnarten/list/lohnarten-list.component').then(
            (m) => m.LohnartenListComponent,
          ),
      },
      {
        path: 'abrechnungskreis',
        loadComponent: () =>
          import('./lohnarten/list/lohnarten-list.component').then(
            (m) => m.LohnartenListComponent,
          ),
      },
    ],
  },
  {
    path: 'hinzufuegen',
    loadComponent: () =>
      import('./lohnarten/detail/lohnarten-detail.component').then(
        (m) => m.LohnartenDetailComponent,
      ),
    data: { lohnKontextReadOnly: true },
  },
  {
    path: ':id',
    loadComponent: () =>
      import('./lohnarten/detail/lohnarten-detail.component').then(
        (m) => m.LohnartenDetailComponent,
      ),
    data: { lohnKontextReadOnly: true },
  },
];

const personalRoutes = createRoute({
  path: 'personal',
  list: () =>
    import('./personal/list/personal-list.component').then(
      (c) => c.PersonalListComponent,
    ),
  detail: () =>
    import('./personal/detail/personal-detail.component').then(
      (c) => c.PersonalDetailComponent,
    ),
  withValidationSupport: true,
  dsm: 'withoutStandard',
  navigation: {
    modelClass: Personal,
    synonyms: [
      'mitarbeiter',
      'eintritt',
      'austritt',
      'vergütung',
      'stundenlohn',
      'gehalt',
    ],
  },
  authorization: { permissions: DEV_READ_REST_ALL_PERMISSIONS },
});
personalRoutes.children
  .filter((c) => c.data?.lohnKontextReadOnly && c.path !== 'hinzufuegen')
  .forEach(
    (c) => (c.data.lohnKontextReadOnly = LohnkontextReadonly.OnlyDateEditable),
  );

export const ROUTES: Routes = extendDomainRoutes([
  { path: '', redirectTo: redirectFirstSibling, pathMatch: 'full' },
  personalRoutes,
  createRoute({
    path: 'kostenstellen',
    list: () =>
      import('./kostenstellen/list/kostenstellen-list.component').then(
        (c) => c.KostenstellenListComponent,
      ),
    detail: () =>
      import('./kostenstellen/detail/kostenstellen-detail.component').then(
        (c) => c.KostenstellenDetailComponent,
      ),
    dsm: 'withoutStandard',
    navigation: {
      icon: MASTERDATA_DEFINITIONS.kostenstelle.icon,
      text: MASTERDATA_DEFINITIONS.kostenstelle.captionPlural,
    },
    authorization: { permissions: DEV_READ_REST_ALL_PERMISSIONS },
  }),
  createRoute({
    path: 'arbeitszeiten',
    list: () =>
      import('./arbeitszeiten/list/arbeitszeiten-list.component').then(
        (c) => c.ArbeitszeitenListComponent,
      ),
    detail: () =>
      import('./arbeitszeiten/detail/arbeitszeiten-detail.component').then(
        (c) => c.ArbeitszeitenDetailComponent,
      ),
    dsm: 'withoutStandard',
    navigation: {
      icon: MASTERDATA_DEFINITIONS.arbeitszeit.icon,
      text: MASTERDATA_DEFINITIONS.arbeitszeit.captionPlural,
    },
    authorization: { permissions: DEV_READ_REST_ALL_PERMISSIONS },
  }),
  createRoute({
    path: 'arbeitszeitflexibilisierungen',
    list: () =>
      import(
        './arbeitszeitflexibilisierungen/list/arbeitszeitflexibilisierungen-list.component'
      ).then((c) => c.ArbeitszeitflexibilisierungenListComponent),
    detail: () =>
      import(
        './arbeitszeitflexibilisierungen/detail/arbeitszeitflexibilisierungen-detail.component'
      ).then((c) => c.ArbeitszeitflexibilisierungenDetailComponent),
    dsm: 'withoutStandard',
    withValidationSupport: true,
    navigation: {
      icon: MASTERDATA_DEFINITIONS.arbeitszeitflexibilisierung.icon,
      text: MASTERDATA_DEFINITIONS.arbeitszeitflexibilisierung.captionPlural,
    },
    authorization: { permissions: DEV_READ_REST_ALL_PERMISSIONS },
  }),
  {
    path: 'lohnarten',
    children: lohnartenChildren,
    data: {
      navigation: {
        icon: MASTERDATA_DEFINITIONS.lohnart.icon,
        text: MASTERDATA_DEFINITIONS.lohnart.captionPlural,
      },
      authorization: {
        permissions: {
          allowRead: [Rolle.VS, Rolle.DEV, Rolle.TS],
          allowAll: [Rolle.AV, Rolle.VSNL, Rolle.VSGL, Rolle.HL, Rolle.PM],
        },
      },
    },
  },
  createRoute({
    path: 'krankenkassen',
    list: () =>
      import('./krankenkassen/list/krankenkassen-list.component').then(
        (c) => c.KrankenkassenListComponent,
      ),
    detail: () =>
      import('./krankenkassen/detail/krankenkassen-detail.component').then(
        (c) => c.KrankenkassenDetailComponent,
      ),
    customDsm: ['lizenznehmer', 'beschaeftigungsbetrieb'],
    customDsmComponent: () =>
      import(
        './krankenkassen/list/krankenkassen-dsm-perspectives-container.component'
      ).then((m) => m.KrankenkassenListDsmPerspectivesContainerComponent),
    navigation: {
      icon: MASTERDATA_DEFINITIONS.krankenkasse.icon,
      text: MASTERDATA_DEFINITIONS.krankenkasse.captionPlural,
    },
    authorization: { permissions: DEV_READ_REST_ALL_PERMISSIONS },
  }),
  createRoute({
    path: 'lohnverbuchungen',
    list: () =>
      import('./lohnverbuchungen/list/lohnverbuchungen-list.component').then(
        (c) => c.LohnverbuchungenListComponent,
      ),
    detail: () =>
      import(
        './lohnverbuchungen/detail/lohnverbuchungen-detail.component'
      ).then((c) => c.LohnverbuchungenDetailComponent),
    dsm: 'all',
    navigation: {
      icon: MASTERDATA_DEFINITIONS.lohnverbuchung.icon,
      text: MASTERDATA_DEFINITIONS.lohnverbuchung.captionPlural,
    },
    authorization: { permissions: DEV_READ_REST_ALL_PERMISSIONS },
  }),
]);
