import { Routes } from '@angular/router';
import { DEV_READ_REST_ALL_PERMISSIONS } from '@salary/common/authorization';
import {
  createRoute,
  extendDomainRoutes,
  redirectFirstSibling,
} from '@salary/common/component';
import { TRANSACTION_DEFINITIONS } from '../transaction-definitions';

const abwesenheitsplanerChildren: Routes = [
  {
    path: '',
    loadComponent: () =>
      import(
        './abwesenheiten/dsm-abwesenheiten-container/dsm-abwesenheiten-container.component'
      ).then((m) => m.DsmAbwesenheitenContainerComponent),
    children: [
      { path: '', redirectTo: 'lizenznehmer', pathMatch: 'full' },
      {
        path: 'lizenznehmer',
        loadComponent: () =>
          import('./abwesenheiten/detail/abwesenheiten.component').then(
            (m) => m.AbwesenheitenComponent,
          ),
      },
      {
        path: 'abrechnungskreis',
        loadComponent: () =>
          import('./abwesenheiten/detail/abwesenheiten.component').then(
            (m) => m.AbwesenheitenComponent,
          ),
      },
      {
        path: 'personal',
        loadComponent: () =>
          import(
            './abwesenheiten/abwesenheiten-container/abwesenheiten-container.component'
          ).then((m) => m.AbwesenheitenContainerComponent),
      },
    ],
  },
];

export const ROUTES: Routes = extendDomainRoutes([
  { path: '', redirectTo: redirectFirstSibling, pathMatch: 'full' },
  createRoute({
    path: 'nachpersonal',
    list: () =>
      import(
        './nach-personal/container/nach-personal-container.component'
      ).then((c) => c.NachPersonalContainerComponent),
    navigation: {
      text: 'nach Personal',
      icon: 'people',
      synonyms: ['stunden', 'erfassung', 'stundenkarte'],
    },
    authorization: { permissions: DEV_READ_REST_ALL_PERMISSIONS },
  }),
  createRoute({
    path: 'nachkostenstellen',
    list: () =>
      import(
        './nach-kostenstellen/container/nach-kostenstellen-container.component'
      ).then((c) => c.NachKostenstellenContainerComponent),
    navigation: {
      text: 'nach Kostenstellen',
      icon: 'layers',
      synonyms: ['stunden', 'erfassung', 'baustelle'],
    },
    authorization: { permissions: DEV_READ_REST_ALL_PERMISSIONS },
  }),
  createRoute({
    path: 'nachlohnarten',
    list: () =>
      import(
        './lohnartenerfassung/list/lohnartenerfassung-list.component'
      ).then((c) => c.LohnartenerfassungListComponent),
    navigation: {
      text: 'nach Lohnarten',
      icon: TRANSACTION_DEFINITIONS.lohnartenerfassung.icon,
      synonyms: ['auv'],
    },
    authorization: { permissions: DEV_READ_REST_ALL_PERMISSIONS },
  }),
  {
    path: 'abwesenheitsplaner',
    children: abwesenheitsplanerChildren,
    data: {
      navigation: {
        icon: TRANSACTION_DEFINITIONS.abwesenheit.icon,
        text: TRANSACTION_DEFINITIONS.abwesenheit.captionPlural,
        synonyms: ['urlaub'],
      },
      authorization: { permissions: DEV_READ_REST_ALL_PERMISSIONS },
    },
  },
  createRoute({
    path: 'ausfallzeiten',
    list: () =>
      import('./ausfallzeiten/list/ausfallzeiten-list.component').then(
        (c) => c.AusfallzeitenListComponent,
      ),
    detail: () =>
      import('./ausfallzeiten/detail/ausfallzeiten-detail.component').then(
        (c) => c.AusfallzeitenDetailComponent,
      ),
    dsm: 'withoutStandard',
    navigation: {
      icon: TRANSACTION_DEFINITIONS.ausfallzeiten.icon,
      text: TRANSACTION_DEFINITIONS.ausfallzeiten.captionPlural,
      synonyms: [
        'krankheitszeiten',
        'kindkrank',
        'beschäftigungsverbot',
        'mutterschutz',
      ],
    },
    authorization: { permissions: DEV_READ_REST_ALL_PERMISSIONS },
  }),
  createRoute({
    path: 'feiertage',
    list: () =>
      import('./feiertage/list/feiertage-list.component').then(
        (c) => c.FeiertageListComponent,
      ),
    dsm: 'all',
    navigation: {
      icon: TRANSACTION_DEFINITIONS.feiertag.icon,
      text: TRANSACTION_DEFINITIONS.feiertag.captionPlural,
      synonyms: ['weihnachten', 'ostern', 'frei'],
    },
    authorization: { permissions: DEV_READ_REST_ALL_PERMISSIONS },
  }),
]);
