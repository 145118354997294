import { Routes } from '@angular/router';
import {
  ALL_PERMISSIONS,
  READONLY_PERMISSIONS,
} from '@salary/common/authorization';
import {
  createRoute,
  extendDomainRoutes,
  redirectFirstSibling,
} from '@salary/common/component';
import { LohnkontextReadonly } from '@salary/common/facade';
import { CALCULATION_DEFINITIONS } from '../calculation-definitions';

const lohnkontoRoute = createRoute({
  path: 'lohnkonten',
  list: () =>
    import('./lohnkonten/list/lohnkonten-list.component').then(
      (c) => c.LohnkontenListComponent,
    ),
  detail: () =>
    import('./lohnkonten/detail/lohnkonten-detail.component').then(
      (c) => c.LohnkontenDetailComponent,
    ),
  dsm: 'withoutStandard',
  withoutHinzufuegen: true,
  navigation: {
    icon: CALCULATION_DEFINITIONS.lohnkonto.icon,
    text: CALCULATION_DEFINITIONS.lohnkonto.captionPlural,
  },
  authorization: { permissions: READONLY_PERMISSIONS },
});
lohnkontoRoute.children
  .filter((c) => c.data?.lohnKontextReadOnly)
  .forEach(
    (c) => (c.data.lohnKontextReadOnly = LohnkontextReadonly.OnlyDateEditable),
  );

export const ROUTES: Routes = extendDomainRoutes([
  { path: '', redirectTo: redirectFirstSibling, pathMatch: 'full' },
  createRoute({
    path: 'probeabrechnungen',
    list: () =>
      import('./probeabrechnung/list/probeabrechnung-list.component').then(
        (c) => c.ProbeabrechnungListComponent,
      ),
    detail: () =>
      import('./probeabrechnung/detail/probeabrechnung-detail.component').then(
        (c) => c.ProbeabrechnungDetailComponent,
      ),
    withoutHinzufuegen: true,
    navigation: {
      icon: CALCULATION_DEFINITIONS.probeabrechnung.icon,
      text: CALCULATION_DEFINITIONS.probeabrechnung.captionPlural,
    },
    authorization: { permissions: ALL_PERMISSIONS },
  }),
  createRoute({
    path: 'schaetzungen',
    list: () =>
      import('./schaetzungen/list/schaetzungen-list.component').then(
        (c) => c.SchaetzungenListComponent,
      ),
    detail: () =>
      import('./schaetzungen/detail/schaetzungen-detail.component').then(
        (c) => c.SchaetzungenDetailComponent,
      ),
    withoutHinzufuegen: true,
    navigation: {
      icon: CALCULATION_DEFINITIONS.schaetzung.icon,
      text: CALCULATION_DEFINITIONS.schaetzung.captionPlural,
      synonyms: ['ermittlung'],
    },
    authorization: { permissions: ALL_PERMISSIONS },
  }),
  createRoute({
    path: 'abrechnungen',
    list: () =>
      import('./abrechnung/list/abrechnung-list.component').then(
        (c) => c.AbrechnungListComponent,
      ),
    detail: () =>
      import('./abrechnung/detail/abrechnungen-detail.component').then(
        (c) => c.AbrechnungenDetailComponent,
      ),
    withoutHinzufuegen: true,
    navigation: {
      icon: CALCULATION_DEFINITIONS.abrechnung.icon,
      text: CALCULATION_DEFINITIONS.abrechnung.captionPlural,
      synonyms: [
        'lsta',
        'btnw',
        'zvk',
        'soka',
        'validierung',
        'fehlerliste',
        'lstb',
        'kea',
        'kug',
        'skug',
        'zahlungen',
        'buchungen',
        'abschluss',
        'freigabe',
        'netto',
      ],
    },
    authorization: { permissions: ALL_PERMISSIONS },
  }),
  createRoute({
    path: 'rueckrechnungen',
    list: () =>
      import('./rueckrechnung/list/rueckrechnungen-list.component').then(
        (c) => c.RueckrechnungenListComponent,
      ),
    withoutHinzufuegen: true,
    dsm: 'withoutStandard',
    navigation: {
      icon: CALCULATION_DEFINITIONS.rueckrechnung.icon,
      text: CALCULATION_DEFINITIONS.rueckrechnung.captionPlural,
      synonyms: ['korrektur'],
    },
    authorization: { permissions: ALL_PERMISSIONS },
  }),
  lohnkontoRoute,
  createRoute({
    path: 'jaehrlichelohnkonten',
    list: () =>
      import(
        './jaehrliche-lohnkonten/list/jaehrliche-lohnkonten-list.component'
      ).then((c) => c.JaehrlicheLohnkontenListComponent),
    detail: () =>
      import(
        './jaehrliche-lohnkonten/detail/jaehrliche-lohnkonten-detail.component'
      ).then((c) => c.JaehrlicheLohnkontenDetailComponent),
    dsm: 'withoutStandard',
    navigation: {
      icon: CALCULATION_DEFINITIONS.jaehrlichesLohnkonto.icon,
      text: CALCULATION_DEFINITIONS.jaehrlichesLohnkonto.captionPlural,
    },
    authorization: { permissions: ALL_PERMISSIONS },
  }),
  createRoute({
    path: 'allgemeineabrechnungsparameter',
    list: () =>
      import(
        './allgemeine-abrechnungsparameter/list/allgemeine-abrechnungsparameter-list.component'
      ).then((c) => c.AllgemeineAbrechnungsparameterListComponent),
    detail: () =>
      import(
        './allgemeine-abrechnungsparameter/detail/allgemeine-abrechnungsparameter-detail.component'
      ).then((c) => c.AllgemeineAbrechnungsparameterDetailComponent),
    withoutHinzufuegen: true,
    navigation: {
      icon: CALCULATION_DEFINITIONS.allgemeineAbrechnungsparameter.icon,
      text: CALCULATION_DEFINITIONS.allgemeineAbrechnungsparameter
        .captionPlural,
      synonyms: ['kirchensteuer', 'grenzen', 'prozent'],
    },
    authorization: { permissions: READONLY_PERMISSIONS },
  }),
  createRoute({
    path: 'sozialversicherungswerte',
    list: () =>
      import(
        './sozialversicherungswerte/list/sozialversicherungswerte-list.component'
      ).then((c) => c.SozialversicherungswerteListComponent),
    detail: () =>
      import(
        './sozialversicherungswerte/detail/sozialversicherungswerte-detail.component'
      ).then((c) => c.SozialversicherungswerteDetailComponent),
    withoutHinzufuegen: true,
    navigation: {
      icon: CALCULATION_DEFINITIONS.sozialversicherungswerte.icon,
      text: CALCULATION_DEFINITIONS.sozialversicherungswerte.captionPlural,
      synonyms: ['kv', 'rv', 'pv', 'av', 'beitragssatz', 'bbg', 'midi', 'mini'],
    },
    authorization: { permissions: READONLY_PERMISSIONS },
  }),
  createRoute({
    path: 'sozialkassenparameter',
    list: () =>
      import(
        './sozialkassenparameter/list/sozialkassenparameter-list.component'
      ).then((c) => c.SozialkassenparameterListComponent),
    detail: () =>
      import(
        './sozialkassenparameter/detail/sozialkassenparameter-detail.component'
      ).then((c) => c.SozialkassenparameterDetailComponent),
    withoutHinzufuegen: true,
    navigation: {
      icon: CALCULATION_DEFINITIONS.sozialkassenparameter.icon,
      text: CALCULATION_DEFINITIONS.sozialkassenparameter.captionPlural,
      synonyms: [
        'tarif',
        'zwg',
        'muv',
        'urlaub',
        'auszubildende',
        'azubi',
        'mek',
        '13',
        'maler',
        'gala',
        'gerüstbau',
      ],
    },
    authorization: { permissions: READONLY_PERMISSIONS },
  }),
  createRoute({
    path: 'durchschnittsloehne',
    list: () =>
      import(
        './durchschnittsloehne/list/durchschnittsloehne-container.component'
      ).then((c) => c.DurchschnittsloehneContainerComponent),
    navigation: {
      icon: CALCULATION_DEFINITIONS.durchschnittslohn.icon,
      text: CALCULATION_DEFINITIONS.durchschnittslohn.captionPlural,
      synonyms: ['monatsschnitt'],
    },
    authorization: { permissions: ALL_PERMISSIONS },
  }),
  createRoute({
    path: 'vortragswertearbeitnehmer',
    list: () =>
      import(
        './vortragswerte-arbeitnehmer/list/vortragswerte-arbeitnehmer-list.component'
      ).then((c) => c.VortragswerteArbeitnehmerListComponent),
    detail: () =>
      import(
        './vortragswerte-arbeitnehmer/detail/vortragswerte-arbeitnehmer-detail.component'
      ).then((c) => c.VortragswerteArbeitnehmerDetailComponent),
    dsm: 'withoutStandard',
    withValidationSupport: true,
    navigation: {
      icon: CALCULATION_DEFINITIONS.vortragswertArbeitnehmer.icon,
      text: CALCULATION_DEFINITIONS.vortragswertArbeitnehmer.captionPlural,
      synonyms: ['vorjahr'],
    },
    authorization: { permissions: ALL_PERMISSIONS },
  }),
  createRoute({
    path: 'vortragswerteurlaubskasse',
    list: () =>
      import(
        './vortragswerte-urlaubskasse/list/vortragswerte-urlaubskasse-list.component'
      ).then((c) => c.VortragswerteUrlaubskasseListComponent),
    detail: () =>
      import(
        './vortragswerte-urlaubskasse/detail/vortragswerte-urlaubskasse-detail.component'
      ).then((c) => c.VortragswerteUrlaubskasseDetailComponent),
    dsm: 'withoutStandard',
    navigation: {
      icon: CALCULATION_DEFINITIONS.vortragswerteUrlaubskasse.icon,
      text: CALCULATION_DEFINITIONS.vortragswerteUrlaubskasse.captionPlural,
      synonyms: [
        'vorfirma',
        'systemwechsel',
        'korrektur',
        'muv',
        'skg',
        'kg',
        'anspruch',
        'einlösung',
        'gerüstbau',
      ],
    },
    authorization: { permissions: ALL_PERMISSIONS },
  }),
  createRoute({
    path: 'vortragswerteazk',
    list: () =>
      import('./vortragswerte-azk/list/vortragswerte-azk-list.component').then(
        (c) => c.VortragswerteAzkListComponent,
      ),
    detail: () =>
      import(
        './vortragswerte-azk/detail/vortragswerte-azk-detail.component'
      ).then((c) => c.VortragswerteAzkDetailComponent),
    dsm: 'withoutStandard',
    navigation: {
      icon: CALCULATION_DEFINITIONS.vortragswerteAZK.icon,
      text: CALCULATION_DEFINITIONS.vortragswerteAZK.captionPlural,
      synonyms: ['siko', 'skug', 'kug'],
    },
    authorization: { permissions: ALL_PERMISSIONS },
  }),
  createRoute({
    path: 'vortragswertesteuersv',
    list: () =>
      import(
        './vortragswerte-steuer-sv/list/vortragswerte-steuer-sv-list.component'
      ).then((c) => c.VortragswerteSteuerSVListComponent),
    detail: () =>
      import(
        './vortragswerte-steuer-sv/detail/vortragswerte-steuer-sv-detail.component'
      ).then((c) => c.VortragswerteSteuerSVDetailComponent),
    dsm: 'withoutStandard',
    navigation: {
      icon: CALCULATION_DEFINITIONS.vortragswerteSteuerSV.icon,
      text: CALCULATION_DEFINITIONS.vortragswerteSteuerSV.captionPlural,
    },
    authorization: { permissions: ALL_PERMISSIONS },
  }),
]);
