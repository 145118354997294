import { Routes } from '@angular/router';
import { ALL_PERMISSIONS } from '@salary/common/authorization';
import {
  createRoute,
  extendDomainRoutes,
  redirectFirstSibling,
} from '@salary/common/component';
import { Rolle } from '@salary/common/dumb';

export const ROUTES: Routes = extendDomainRoutes([
  { path: '', redirectTo: redirectFirstSibling, pathMatch: 'full' },
  createRoute({
    path: 'lohn',
    list: () =>
      import('./lohn/lohn-list.component').then((c) => c.LohnListComponent),
    dsm: 'withoutStandard',
    navigation: { text: 'Lohn', icon: 'description' },
    authorization: { permissions: ALL_PERMISSIONS },
  }),
  createRoute({
    path: 'personalakte',
    list: () =>
      import('./personalakte/personalakte-list.component').then(
        (c) => c.PersonalakteListComponent,
      ),
    dsm: 'withoutStandard',
    navigation: {
      text: 'Personalakte',
      icon: 'contact_page',
      synonyms: ['netto', 'nettoformular'],
    },
    authorization: { permissions: ALL_PERMISSIONS },
  }),
  createRoute({
    path: 'listen',
    list: () =>
      import('./listen/list/listen-list.component').then(
        (c) => c.ListenListComponent,
      ),
    detail: () =>
      import('./listen/detail/listen-detail-component').then(
        (c) => c.ListenDetailComponent,
      ),
    customDsm: ['standard', 'eigene'],
    customDsmComponent: () =>
      import('./listen/list/listen-dsm-perspectives-container.component').then(
        (m) => m.ListenDsmPerspectivesContainerComponent,
      ),
    navigation: { text: 'Listen', icon: 'query_stats' },
    authorization: {
      permissions: {
        allowAll: [Rolle.DEV, Rolle.PM],
        allowNone: [
          Rolle.AV,
          Rolle.HL,
          Rolle.TS,
          Rolle.VS,
          Rolle.VSNL,
          Rolle.VSGL,
        ],
      },
    },
  }),
  createRoute({
    path: 'verarbeitungsstatus',
    list: () =>
      import(
        './verarbeitungsstatus/container/verarbeitungsstatus-container.component'
      ).then((c) => c.VerarbeitungsstatusContainerComponent),
    navigation: {
      text: 'Verarbeitungsstatus',
      icon: 'all_match',
      synonyms: [
        'abrechnung',
        'ermittlung',
        'schätzung',
        'deüv',
        'freigabe',
        'sonderauswertung',
        'monatlich',
        'jährlich',
      ],
    },
    authorization: { permissions: ALL_PERMISSIONS },
  }),
]);
