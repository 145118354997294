import {
  animate,
  keyframes,
  query,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const routeTransitionAnimations = trigger('outletTrigger', [
  transition('* => void', []),
  transition(
    'list1 => list2, list2 => list1, detail1 => list1, detail2 => list1',
    [
      query(
        ':enter, :leave',
        [style({ position: 'absolute', width: '100%', height: '100%' })],
        { optional: true },
      ),
      query(':leave', [style({ opacity: 0 })], { optional: true }),

      query(
        ':enter',
        [
          animate(
            '1000ms cubic-bezier(0.25, 0.8, 0.25, 1)',
            keyframes([
              style({
                transform: 'translate3d(-50%, 0, 0)',
                opacity: 0,
                offset: 0,
              }),
              style({
                transform: 'translate3d(0, 0, 0)',
                opacity: 0.7,
                offset: 0.4,
              }),
              style({ opacity: 1, offset: 1 }),
            ]),
          ),
        ],
        { optional: true },
      ),
    ],
  ),
  transition(
    'detail1 => detail2, detail2 => detail1, list1 => detail1, list2 => detail1',
    [
      query(':self', [style({ overflow: 'hidden' })]),
      query(
        ':enter, :leave',
        [style({ position: 'absolute', width: '100%', height: '100%' })],
        { optional: true },
      ),
      query(':leave', [style({ opacity: 0 })], { optional: true }),
      query(
        ':enter',
        [
          animate(
            '1000ms cubic-bezier(0.25, 0.8, 0.25, 1)',
            keyframes([
              style({
                transform: 'translate3d(50%, 0, 0)',
                opacity: 0,
                offset: 0,
              }),
              style({
                transform: 'translate3d(0, 0, 0)',
                opacity: 0.3,
                offset: 0.4,
              }),
              style({ opacity: 1, offset: 1 }),
            ]),
          ),
        ],
        { optional: true },
      ),
    ],
  ),
]);
