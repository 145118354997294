export const APP_LIST_CONFIG_REGISTRATION = [
  () =>
    import('../masterdata/component/masterdata-list.config').then(
      (m) => m.LIST_CONFIG_REGISTRATION,
    ),
  () =>
    import('../transaction/component/transaction-list.config').then(
      (m) => m.LIST_CONFIG_REGISTRATION,
    ),
  () =>
    import('../calculation/component/calculation-list.config').then(
      (m) => m.LIST_CONFIG_REGISTRATION,
    ),
];
