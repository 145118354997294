import { Route } from '@angular/router';
import {
  RZ_PROZESS_DEFINITION,
  RZ_PROZESSE_TOKEN,
} from './rz-prozess-definition';
import { RZ_PROZESSE } from './rz-prozesse-config';

export const RZ_PROZESSE_ROUTES: Route[] = RZ_PROZESSE.filter(
  (p) => p.apiUrl,
).map((process) => {
  const children: Route[] = [
    {
      path: '',
      loadComponent: () =>
        import('./rz-prozess-list.component').then(
          (m) => m.RZProzessListComponent,
        ),
      providers: [
        { provide: RZ_PROZESS_DEFINITION, useValue: process },
        { provide: RZ_PROZESSE_TOKEN, useValue: RZ_PROZESSE },
      ],
    },
  ];
  if (process.detailComponent) {
    children.push({
      path: ':id',
      loadComponent: process.detailComponent,
      data: process.detailComponentData,
      providers: [
        { provide: RZ_PROZESS_DEFINITION, useValue: process },
        { provide: RZ_PROZESSE_TOKEN, useValue: RZ_PROZESSE },
      ],
    });
  }
  return { path: process.route, children };
});
