import { Routes } from '@angular/router';
import { FALLBACK_PERMISSIONS } from '@salary/common/authorization';
import {
  createRoute,
  extendDomainRoutes,
  redirectFirstSibling,
} from '@salary/common/component';
import { Abrechnungsfreigabe, Rolle } from '@salary/common/dumb';
import { ADMINISTRATION_DEFINITIONS } from '../administration-definitions';

export const ROUTES: Routes = extendDomainRoutes([
  { path: '', redirectTo: redirectFirstSibling, pathMatch: 'full' },
  createRoute({
    path: 'lizenznehmer',
    list: () =>
      import('./lizenznehmer/list/lizenznehmer-list.component').then(
        (c) => c.LizenznehmerListComponent,
      ),
    detail: () =>
      import('./lizenznehmer/detail/lizenznehmer-detail.component').then(
        (c) => c.LizenznehmerDetailComponent,
      ),
    navigation: {
      icon: ADMINISTRATION_DEFINITIONS.lizenznehmer.icon,
      text: ADMINISTRATION_DEFINITIONS.lizenznehmer.captionPlural,
    },
    authorization: {
      permissions: {
        allowNone: [Rolle.TS],
        allowRead: [Rolle.DEV, Rolle.HL, Rolle.VS, Rolle.VSNL, Rolle.VSGL],
        allowAll: [Rolle.AV, Rolle.PM],
      },
    },
  }),
  createRoute({
    path: 'rzabrechnungskreise',
    withValidationSupport: true,
    list: () =>
      import(
        './rz-abrechnungskreise/list/rz-abrechnungskreise-list.component'
      ).then((m) => m.RZAbrechnungskreiseListComponent),
    detail: () =>
      import(
        './rz-abrechnungskreise/detail/rz-abrechnungskreise-detail.component'
      ).then((m) => m.RZAbrechnungskreiseDetailComponent),
    navigation: {
      icon: ADMINISTRATION_DEFINITIONS.rzabrechnungskreis.icon,
      text: ADMINISTRATION_DEFINITIONS.rzabrechnungskreis.captionPlural,
      synonyms: [
        'lohnbeginn',
        'lohnende',
        'fakturierung',
        'module',
        'fakturierungszeilen',
      ],
    },
    authorization: {
      permissions: {
        allowNone: [Rolle.TS],
        allowRead: [Rolle.HL, Rolle.DEV, Rolle.VS, Rolle.VSNL, Rolle.VSGL],
        allowAll: [Rolle.AV, Rolle.PM],
      },
      children: [
        {
          path: 'q5Hgta',
          permissions: {
            allowNone: [Rolle.TS],
            allowRead: [Rolle.HL, Rolle.DEV, Rolle.VS, Rolle.VSNL],
            allowAll: [Rolle.AV, Rolle.PM, Rolle.VSGL],
          },
        },
        { path: 'RL1yDe', permissions: FALLBACK_PERMISSIONS },
      ],
    },
  }),
  createRoute({
    path: 'benutzer',
    list: () =>
      import('./benutzer/list/benutzer-list.component').then(
        (m) => m.BenutzerListComponent,
      ),
    detail: () =>
      import('./benutzer/detail/benutzer-detail.component').then(
        (m) => m.BenutzerDetailComponent,
      ),
    navigation: {
      icon: ADMINISTRATION_DEFINITIONS.benutzer.icon,
      text: ADMINISTRATION_DEFINITIONS.benutzer.captionPlural,
    },
    authorization: {
      permissions: {
        allowNone: [Rolle.TS, Rolle.VS, Rolle.VSGL, Rolle.VSNL],
        allowRead: [Rolle.DEV, Rolle.HL],
        allowAll: [Rolle.PM, Rolle.AV],
      },
    },
  }),
  createRoute({
    path: 'niederlassungen',
    list: () =>
      import('./niederlassungen/list/niederlassungen-list.component').then(
        (m) => m.NiederlassungenListComponent,
      ),
    detail: () =>
      import('./niederlassungen/detail/niederlassungen-detail.component').then(
        (m) => m.NiederlassungenDetailComponent,
      ),
    navigation: {
      icon: ADMINISTRATION_DEFINITIONS.niederlassung.icon,
      text: ADMINISTRATION_DEFINITIONS.niederlassung.captionPlural,
      synonyms: ['filiale'],
    },
    authorization: {
      permissions: {
        allowNone: [Rolle.VS, Rolle.VSNL, Rolle.VSGL, Rolle.TS],
        allowRead: [Rolle.HL, Rolle.DEV],
        allowAll: [Rolle.PM, Rolle.AV],
      },
    },
  }),
  createRoute({
    path: 'abrechnungsfreigaben',
    list: () =>
      import('./abrechnungsfreigaben/abrechnungsfreigaben-list.component').then(
        (m) => m.AbrechnungsfreigabenListComponent,
      ),
    navigation: { modelClass: Abrechnungsfreigabe },
    authorization: {
      permissions: {
        allowNone: [Rolle.VS, Rolle.VSNL, Rolle.VSGL, Rolle.TS],
        allowRead: [Rolle.HL, Rolle.DEV],
        allowAll: [Rolle.PM, Rolle.AV],
      },
    },
  }),
  createRoute({
    path: 'termine',
    list: () =>
      import('./termine/termine.component').then((m) => m.TermineComponent),
    navigation: {
      icon: ADMINISTRATION_DEFINITIONS.termin.icon,
      text: ADMINISTRATION_DEFINITIONS.termin.captionPlural,
      synonyms: ['kalender'],
    },
    authorization: {
      permissions: {
        allowRead: [Rolle.DEV],
        allowAll: [Rolle.PM, Rolle.AV],
        allowNone: [Rolle.HL, Rolle.VS, Rolle.VSNL, Rolle.VSGL, Rolle.TS],
      },
    },
  }),
]);
